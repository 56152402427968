import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import type { PageWithQueryData } from '@pafcloud/with-data';
import { SanityPage } from '@pafcloud/sanity-blocks';
import { PageContainer } from '@pafcloud/base-components';
import { usePlayerStatus } from '@pafcloud/contexts';
import { $buildEnv } from '@pafcloud/config/buildEnv';
import { PageSeoText } from '@pafcloud/block-components';
import type { HomePageQuery } from './__generated__/HomePageQuery.graphql';
import type { HomePage_pageData$key } from './__generated__/HomePage_pageData.graphql';
import { NotFoundPage } from './NotFoundPage';

const SkeletonPage = styled(PageContainer)({
  minHeight: '50vh',
});

const homePageDataFragment = graphql`
  fragment HomePage_pageData on Page {
    ...SanityPage_page
    ...PageSeoText_data
  }
`;

export const HomePage: PageWithQueryData<HomePageQuery> = (props) => {
  const page = useFragment<HomePage_pageData$key>(homePageDataFragment, props.pageData.homePage);
  const isLoadingPlayer = usePlayerStatus() === 'LOADING';

  if (page == null) {
    return <NotFoundPage />;
  }

  if ($buildEnv.site === 'x3000.com' || $buildEnv.site === 'x3000.ee' || $buildEnv.site === 'x3000.lv') {
    if (isLoadingPlayer) {
      return <SkeletonPage />;
    }
  }

  return (
    <>
      <PageContainer>
        <SanityPage page={page} />
      </PageContainer>

      <PageSeoText data={page} />
    </>
  );
};

HomePage.query = graphql`
  query HomePageQuery {
    ...App_data
    homePage {
      ...HomePage_pageData
    }
  }
`;
