/**
 * @generated SignedSource<<fe2e4d7b1e60d444a3966ffcc9213d61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePage_pageData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PageSeoText_data" | "SanityPage_page">;
  readonly " $fragmentType": "HomePage_pageData";
};
export type HomePage_pageData$key = {
  readonly " $data"?: HomePage_pageData$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePage_pageData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePage_pageData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SanityPage_page"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PageSeoText_data"
    }
  ],
  "type": "Page",
  "abstractKey": null
};

(node as any).hash = "cc02904470543d09b51c94aa47cd3356";

export default node;
